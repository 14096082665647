@import './variables.less';

@root-entry-name: variable;
@import '../node_modules/ng-zorro-antd/style/entry.less';
@import '../node_modules/ng-zorro-antd/alert/style/entry.less';
@import '../node_modules/ng-zorro-antd/button/style/entry.less';
@import '../node_modules/ng-zorro-antd/badge/style/entry.less';
@import '../node_modules/ng-zorro-antd/card/style/entry.less';
@import '../node_modules/ng-zorro-antd/checkbox/style/entry.less';
@import '../node_modules/ng-zorro-antd/comment/style/entry.less';
@import '../node_modules/ng-zorro-antd/date-picker/style/entry.less';
@import '../node_modules/ng-zorro-antd/descriptions/style/entry.less';
@import '../node_modules/ng-zorro-antd/dropdown/style/entry.less';
@import '../node_modules/ng-zorro-antd/grid/style/entry.less';
@import '../node_modules/ng-zorro-antd/icon/style/entry.less';
@import '../node_modules/ng-zorro-antd/form/style/entry.less';
@import '../node_modules/ng-zorro-antd/input/style/entry.less';
@import '../node_modules/ng-zorro-antd/input-number/style/entry.less';
@import '../node_modules/ng-zorro-antd/layout/style/entry.less';
@import '../node_modules/ng-zorro-antd/list/style/entry.less';
@import '../node_modules/ng-zorro-antd/menu/style/entry.less';
@import '../node_modules/ng-zorro-antd/modal/style/entry.less';
@import '../node_modules/ng-zorro-antd/notification/style/entry.less';
@import '../node_modules/ng-zorro-antd/page-header/style/entry.less';
@import '../node_modules/ng-zorro-antd/popover/style/entry.less';
@import '../node_modules/ng-zorro-antd/result/style/entry.less';
@import '../node_modules/ng-zorro-antd/select/style/entry.less';
@import '../node_modules/ng-zorro-antd/steps/style/entry.less';
@import '../node_modules/ng-zorro-antd/spin/style/entry.less';
@import '../node_modules/ng-zorro-antd/table/style/entry.less';
@import '../node_modules/ng-zorro-antd/tabs/style/entry.less';
@import '../node_modules/ng-zorro-antd/tag/style/entry.less';
@import '../node_modules/ng-zorro-antd/tooltip/style/entry.less';
@import '../node_modules/ng-zorro-antd/time-picker/style/entry.less';
@import '../node_modules/ng-zorro-antd/drawer/style/entry.less';
@import './overrides/pagination/entry.less';
// @import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";
// The prefix to use on all css classes from ant.
@ant-prefix: ant;

// -------- Colors -----------
@primary-color: @blue-6;
@info-color: @blue-6;
@success-color: @green-6;
@processing-color: @blue-6;
@error-color: @red-6;
@highlight-color: @red-6;
@warning-color: @gold-6;
@normal-color: #d9d9d9;

// Color used by default to control hover and active backgrounds and for
// alert info backgrounds.
@primary-1: color(~`colorPalette('@{primary-color}', 1) `); // replace tint(@primary-color, 90%)
@primary-2: color(~`colorPalette('@{primary-color}', 2) `); // replace tint(@primary-color, 80%)
@primary-3: color(~`colorPalette('@{primary-color}', 3) `); // unused
@primary-4: color(~`colorPalette('@{primary-color}', 4) `); // unused
@primary-5: color(
  ~`colorPalette('@{primary-color}', 5) `
); // color used to control the text color in many active and hover states, replace tint(@primary-color, 20%)
@primary-6: @primary-color; // color used to control the text color of active buttons, don't use, use @primary-color
@primary-7: color(~`colorPalette('@{primary-color}', 7) `); // replace shade(@primary-color, 5%)
@primary-8: color(~`colorPalette('@{primary-color}', 8) `); // unused
@primary-9: color(~`colorPalette('@{primary-color}', 9) `); // unused
@primary-10: color(~`colorPalette('@{primary-color}', 10) `); // unused

// Base Scaffolding Variables
// ---
// Background color for `<body>`
@body-background: #fff;
// Base background color for most components
@component-background: #fff;
@font-family: 'Inter', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
@variable-font-family: 'Inter var', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
@code-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
@heading-color: fade(#000, 85%);
@text-color: fade(#000, 65%);
@text-color-secondary: fade(#000, 45%);
@heading-color-dark: fade(#fff, 100%);
@text-color-dark: fade(#fff, 85%);
@text-color-secondary-dark: fade(#fff, 65%);
@font-variant-base: tabular-nums;
@font-size-base: 14px;
@font-size-lg: @font-size-base + 2px;
@font-size-sm: 12px;
@line-height-base: 1.5;
@border-radius-base: 4px;
@border-radius-sm: 2px;

// vertical paddings
@padding-lg: 24px; // containers
@padding-md: 16px; // small containers and buttons
@padding-sm: 12px; // Form controls and items
@padding-xs: 8px; // small items

// vertical padding for all form controls
@control-padding-horizontal: @padding-sm;
@control-padding-horizontal-sm: @padding-xs;

// The background colors for active and hover states for things like
// list items or table cells.
@item-active-bg: @primary-1;
@item-hover-bg: @primary-1;

// ICONFONT
@iconfont-css-prefix: anticon;

// LINK
@link-color: @primary-color;
@link-hover-color: color(~`colorPalette('@{link-color}', 5) `);
@link-active-color: color(~`colorPalette('@{link-color}', 7) `);
@link-decoration: none;
@link-hover-decoration: none;

// Animation
@ease-base-out: cubic-bezier(0.7, 0.3, 0.1, 1);
@ease-base-in: cubic-bezier(0.9, 0, 0.3, 0.7);
@ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);
@ease-in: cubic-bezier(0.55, 0.055, 0.675, 0.19);
@ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
@ease-out-back: cubic-bezier(0.12, 0.4, 0.29, 1.46);
@ease-in-back: cubic-bezier(0.71, -0.46, 0.88, 0.6);
@ease-in-out-back: cubic-bezier(0.71, -0.46, 0.29, 1.46);
@ease-out-circ: cubic-bezier(0.08, 0.82, 0.17, 1);
@ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.34);
@ease-in-out-circ: cubic-bezier(0.78, 0.14, 0.15, 0.86);
@ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
@ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
@ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);

// Border color
@border-color-base: hsv(0, 0, 85%); // base border outline a component
@border-color-split: hsv(0, 0, 91%); // split border inside a component
@border-width-base: 1px; // width of the border for a component
@border-style-base: solid; // style of a components border

// Outline
@outline-blur-size: 0;
@outline-width: 2px;
@outline-color: @primary-color;

@background-color-light: hsv(0, 0, 98%); // background of header and selected item
@background-color-base: hsv(0, 0, 96%); // Default grey background color

// Disabled states
@disabled-color: fade(#000, 25%);
@disabled-bg: @background-color-base;
@disabled-color-dark: fade(#fff, 35%);

// Shadow
@shadow-color: rgba(0, 0, 0, 0.15);
@box-shadow-base: @shadow-1-down;
@shadow-1-up: 0 -2px 8px @shadow-color;
@shadow-1-down: 0 2px 8px @shadow-color;
@shadow-1-left: -2px 0 8px @shadow-color;
@shadow-1-right: 2px 0 8px @shadow-color;
@shadow-2: 0 4px 12px @shadow-color;

// Buttons
@btn-font-weight: 400;
@btn-border-radius-base: @border-radius-base;
@btn-border-radius-sm: @border-radius-base;

@btn-primary-color: #fff;
@btn-primary-bg: @primary-color;

@btn-default-color: @text-color;
@btn-default-bg: #fff;
@btn-default-border: @border-color-base;

@btn-danger-color: @error-color;
@btn-danger-bg: @background-color-base;
@btn-danger-border: @border-color-base;

@btn-disable-color: @disabled-color;
@btn-disable-bg: @disabled-bg;
@btn-disable-border: @border-color-base;

@btn-padding-base: 0 @padding-md - 1px;
@btn-font-size-lg: @font-size-lg;
@btn-font-size-sm: @font-size-base;
@btn-padding-lg: @btn-padding-base;
@btn-padding-sm: 0 @padding-xs - 1px;

@btn-height-base: 32px;
@btn-height-lg: 40px;
@btn-height-sm: 24px;

@btn-circle-size: @btn-height-base;
@btn-circle-size-lg: @btn-height-lg;
@btn-circle-size-sm: @btn-height-sm;

@btn-group-border: @primary-5;

// Checkbox
@checkbox-size: 16px;
@checkbox-color: @primary-color;
@checkbox-check-color: #fff;
@checkbox-border-width: @border-width-base;

// Radio
@radio-size: 16px;
@radio-dot-color: @primary-color;

// Radio buttons
@radio-button-bg: @btn-default-bg;
@radio-button-color: @btn-default-color;
@radio-button-hover-color: @primary-5;
@radio-button-active-color: @primary-7;

// Media queries breakpoints
// Extra small screen / phone
@screen-xs: 480px;
@screen-xs-min: @screen-xs;

// Small screen / tablet
@screen-sm: 576px;
@screen-sm-min: @screen-sm;

// Medium screen / desktop
@screen-md: 768px;
@screen-md-min: @screen-md;

// Large screen / wide desktop
@screen-lg: 992px;
@screen-lg-min: @screen-lg;

// Extra large screen / full hd
@screen-xl: 1200px;
@screen-xl-min: @screen-xl;

// Extra extra large screen / large descktop
@screen-xxl: 1600px;
@screen-xxl-min: @screen-xxl;

// provide a maximum
@screen-xs-max: (@screen-sm-min - 1px);
@screen-sm-max: (@screen-md-min - 1px);
@screen-md-max: (@screen-lg-min - 1px);
@screen-lg-max: (@screen-xl-min - 1px);
@screen-xl-max: (@screen-xxl-min - 1px);

// Grid system
@grid-columns: 24;
@grid-gutter-width: 0;

// Layout
@layout-body-background: #f0f2f5;
@layout-header-background: #001529;
@layout-footer-background: @layout-body-background;
@layout-header-height: 64px;
@layout-header-padding: 0 50px;
@layout-footer-padding: 24px 50px;
@layout-sider-background: @layout-header-background;
@layout-trigger-height: 48px;
@layout-trigger-background: #002140;
@layout-trigger-color: #fff;
@layout-zero-trigger-width: 36px;
@layout-zero-trigger-height: 42px;
// Layout light theme
@layout-sider-background-light: #fff;
@layout-trigger-background-light: #fff;
@layout-trigger-color-light: @text-color;

// z-index list
@zindex-affix: 10;
@zindex-back-top: 10;
@zindex-modal-mask: 1000;
@zindex-modal: 1000;
@zindex-notification: 1010;
@zindex-message: 1010;
@zindex-popover: 1030;
@zindex-picker: 1050;
@zindex-dropdown: 1050;
@zindex-tooltip: 1060;

// Animation
@animation-duration-slow: 0.3s; // Modal
@animation-duration-base: 0.2s;
@animation-duration-fast: 0.1s; // Tooltip

// Form
// ---
@label-required-color: @highlight-color;
@label-color: @heading-color;
@form-item-margin-bottom: 24px;
@form-item-trailing-colon: true;
@form-vertical-label-padding: 0 0 8px;
@form-vertical-label-margin: 0;

// Input
// ---
@input-height-base: 32px;
@input-height-lg: 40px;
@input-height-sm: 24px;
@input-padding-horizontal: @control-padding-horizontal - 1px;
@input-padding-horizontal-base: @input-padding-horizontal;
@input-padding-horizontal-sm: @control-padding-horizontal-sm - 1px;
@input-padding-horizontal-lg: @input-padding-horizontal;
@input-padding-vertical-base: 4px;
@input-padding-vertical-sm: 1px;
@input-padding-vertical-lg: 6px;
@input-placeholder-color: hsv(0, 0, 75%);
@input-color: @text-color;
@input-border-color: @border-color-base;
@input-bg: #fff;
@input-addon-bg: @background-color-light;
@input-hover-border-color: @primary-color;
@input-disabled-bg: @disabled-bg;
@input-outline-offset: 0 0;

// Tooltip
// ---
//* Tooltip max width
@tooltip-max-width: 250px;
@import '../node_modules/ng-zorro-antd/alert/style/entry.less';
@tooltip-color: #fff;
@import '../node_modules/ng-zorro-antd/alert/style/entry.less';
@tooltip-bg: rgba(0, 0, 0, 0.75);
@import '../node_modules/ng-zorro-antd/alert/style/entry.less';
@tooltip-arrow-width: 5px;
@import '../node_modules/ng-zorro-antd/alert/style/entry.less';
@tooltip-distance: @tooltip-arrow-width - 1px + 4px;
@import '../node_modules/ng-zorro-antd/alert/style/entry.less';
@tooltip-arrow-color: @tooltip-bg;
@import '../node_modules/ng-zorro-antd/button/style/entry.less';
@import '../node_modules/ng-zorro-antd/badge/style/entry.less';
@import '../node_modules/ng-zorro-antd/card/style/entry.less';
@popover-bg: #fff;
@import '../node_modules/ng-zorro-antd/alert/style/entry.less';
@popover-color: @text-color;
@import '../node_modules/ng-zorro-antd/alert/style/entry.less';
@popover-min-width: 177px;
@import '../node_modules/ng-zorro-antd/alert/style/entry.less';
@popover-arrow-width: 6px;
@import '../node_modules/ng-zorro-antd/alert/style/entry.less';
@popover-arrow-color: @popover-bg;
@import '../node_modules/ng-zorro-antd/alert/style/entry.less';
@import '../node_modules/ng-zorro-antd/style/entry.less';
@popover-arrow-outer-color: @popover-bg;
@import '../node_modules/ng-zorro-antd/alert/style/entry.less';
@popover-distance: @popover-arrow-width + 4px;
@import '../node_modules/ng-zorro-antd/button/style/entry.less';
@import '../node_modules/ng-zorro-antd/badge/style/entry.less';
@modal-header-bg: @component-background;
@modal-mask-bg: rgba(0, 0, 0, 0.65);
@import '../node_modules/ng-zorro-antd/date-picker/style/entry.less';
@import '../node_modules/ng-zorro-antd/descriptions/style/entry.less';
@progress-default-color: @processing-color;
@progress-remaining-color: @background-color-base;
@progress-text-color: @text-color;
@import '../node_modules/ng-zorro-antd/input/style/entry.less';
@import '../node_modules/ng-zorro-antd/input-number/style/entry.less';
@menu-inline-toplevel-item-height: 40px;
@menu-item-height: 40px;
@menu-collapsed-width: 80px;
@menu-bg: @component-background;
@menu-popup-bg: @component-background;
@menu-item-color: @text-color;
@menu-highlight-color: @primary-color;
@menu-item-active-bg: @item-active-bg;
@menu-item-active-border-width: 3px;
@menu-item-group-title-color: @text-color-secondary;
@import '../node_modules/ng-zorro-antd/spin/style/entry.less';
@menu-dark-color: @text-color-secondary-dark;
@menu-dark-bg: @layout-header-background;
@menu-dark-arrow-color: #fff;
@menu-dark-submenu-bg: #000c17;
@menu-dark-highlight-color: #fff;
@menu-dark-item-active-bg: @primary-color;
// @import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";
// The prefix to use on all css classes from ant.
@spin-dot-size-sm: 14px;
@spin-dot-size: 20px;
@spin-dot-size-lg: 32px;
@info-color: @blue-6;
@success-color: @green-6;
@table-header-bg: @background-color-light;
@table-header-color: @heading-color;
@table-header-sort-bg: @background-color-base;
@table-body-sort-bg: rgba(0, 0, 0, 0.01);
@table-row-hover-bg: @primary-1;
@table-selected-row-bg: #fafafa;
@table-expanded-row-bg: #fbfbfb;
@table-padding-vertical: 16px;
@table-padding-horizontal: 16px;
@primary-3: color(~`colorPalette('@{primary-color}', 3) `); // unused
@primary-4: color(~`colorPalette('@{primary-color}', 4) `); // unused
@tag-default-bg: @background-color-light;
@tag-default-color: @text-color;
@tag-font-size: @font-size-sm;
@primary-7: color(~`colorPalette('@{primary-color}', 7) `); // replace shade(@primary-color, 5%)
@primary-8: color(~`colorPalette('@{primary-color}', 8) `); // unused
@time-picker-panel-column-width: 56px;
@time-picker-panel-width: @time-picker-panel-column-width * 3;

@time-picker-selected-bg: @background-color-base;
// ---
// Background color for `<body>`
@carousel-dot-width: 16px;
@carousel-dot-height: 3px;
@carousel-dot-active-width: 24px;
@variable-font-family: 'Inter var', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
@code-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
@badge-height: 20px;
@badge-dot-size: 6px;
@badge-font-size: @font-size-sm;
@badge-font-weight: 400;
@badge-status-size: 6px;
@font-variant-base: tabular-nums;
@font-size-base: 14px;
@rate-star-color: @yellow-6;
@rate-star-bg: @border-color-split;
@border-radius-base: 4px;
@border-radius-sm: 2px;

@card-head-color: @heading-color;
@card-head-background: transparent;
@card-head-padding: 16px;
@card-inner-head-padding: 12px;
@card-padding-base: 24px;
@card-padding-wider: 32px;
@card-actions-background: @background-color-light;
@card-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
@control-padding-horizontal-sm: @padding-xs;

@comment-padding-base: 16px 0;
@comment-nest-indent: 44px;
@comment-author-name-color: @text-color-secondary;
@comment-author-time-color: #ccc;
@comment-action-color: @text-color-secondary;
@comment-action-hover-color: #595959;

// LINK
@tabs-card-head-background: @background-color-light;
@tabs-card-height: 40px;
@tabs-card-active-color: @primary-color;
@tabs-title-font-size: @font-size-base;
@tabs-title-font-size-lg: @font-size-lg;
@tabs-title-font-size-sm: @font-size-base;
@tabs-ink-bar-color: @primary-color;
@tabs-bar-margin: 0 0 16px 0;
@tabs-horizontal-margin: 0 32px 0 0;
@tabs-horizontal-padding: 12px 16px;
@tabs-vertical-padding: 8px 24px;
@tabs-vertical-margin: 0 0 16px 0;
@tabs-scrolling-size: 32px;
@tabs-highlight-color: @primary-color;
@tabs-hover-color: @primary-5;
@tabs-active-color: @primary-7;
@ease-in-out-circ: cubic-bezier(0.78, 0.14, 0.15, 0.86);
@ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
@back-top-color: #fff;
@back-top-bg: @text-color-secondary;
@back-top-hover-bg: @text-color;
@border-color-base: hsv(0, 0, 85%); // base border outline a component
@border-color-split: hsv(0, 0, 91%); // split border inside a component
@avatar-size-base: 32px;
@avatar-size-lg: 40px;
@avatar-size-sm: 24px;
@avatar-font-size-base: 18px;
@avatar-font-size-lg: 24px;
@avatar-font-size-sm: 14px;
@avatar-bg: #ccc;
@avatar-color: #fff;
@avatar-border-radius: @border-radius-base;

// Disabled states
@switch-height: 22px;
@switch-sm-height: 16px;
@switch-sm-checked-margin-left: -(@switch-sm-height - 3px);
@switch-disabled-opacity: 0.4;
@switch-color: @primary-color;
@box-shadow-base: @shadow-1-down;
@shadow-1-up: 0 -2px 8px @shadow-color;
@pagination-item-size: 32px;
@pagination-item-size-sm: 24px;
@pagination-font-family: 'Inter', sans-serif;
@pagination-variable-font-family: 'Inter var', sans-serif;
@pagination-font-weight-active: 500;
@btn-font-weight: 400;
@btn-border-radius-base: @border-radius-base;
@breadcrumb-base-color: @text-color-secondary;
@breadcrumb-last-item-color: @text-color;
@breadcrumb-font-size: @font-size-base;
@breadcrumb-icon-font-size: @font-size-base;
@breadcrumb-link-color: @text-color-secondary;
@breadcrumb-link-color-hover: @primary-5;
@breadcrumb-separator-color: @text-color-secondary;
@breadcrumb-separator-margin: 0 @padding-xs;
@btn-danger-color: @error-color;
@btn-danger-bg: @background-color-base;
@slider-margin: 14px 6px 10px;
@slider-rail-background-color: @background-color-base;
@slider-rail-background-color-hover: #e1e1e1;
@slider-track-background-color: @primary-5;
@slider-track-background-color-hover: @primary-6;
@slider-handle-color: @primary-5;
@slider-handle-color-hover: @primary-6;
@slider-handle-color-focus: tint(@primary-color, 20%);
@slider-handle-color-focus-shadow: fade(@primary-color, 20%);
@slider-handle-color-tooltip-open: @primary-color;
@slider-dot-border-color: @border-color-split;
@slider-dot-border-color-active: @primary-5;
@slider-disabled-color: @disabled-color;
@slider-disabled-background-color: @component-background;

@btn-circle-size: @btn-height-base;
@tree-title-height: 24px;
@tree-child-padding: 18px;
@tree-directory-selected-color: #fff;
@tree-directory-selected-bg: @primary-color;
// Checkbox
@checkbox-size: 16px;
@collapse-header-padding: 12px 16px 12px 40px;
@collapse-header-bg: @background-color-light;
@collapse-content-padding: @padding-md;
@collapse-content-bg: @component-background;
@radio-size: 16px;
@radio-dot-color: @primary-color;

@skeleton-color: #f2f2f2;
@radio-button-bg: @btn-default-bg;
@radio-button-color: @btn-default-color;
@transfer-disabled-bg: @disabled-bg;

// Media queries breakpoints
@message-notice-content-padding: 10px 16px;
@screen-xs-min: @screen-xs;

@wave-animation-width: 6px;
@screen-sm-min: @screen-sm;

@alert-success-border-color: ~`colorPalette('@{success-color}', 3) `;
@alert-success-bg-color: ~`colorPalette('@{success-color}', 1) `;
@alert-success-icon-color: @success-color;
@alert-info-border-color: ~`colorPalette('@{info-color}', 3) `;
@alert-info-bg-color: ~`colorPalette('@{info-color}', 1) `;
@alert-info-icon-color: @info-color;
@alert-warning-border-color: ~`colorPalette('@{warning-color}', 3) `;
@alert-warning-bg-color: ~`colorPalette('@{warning-color}', 1) `;
@alert-warning-icon-color: @warning-color;
@alert-error-border-color: ~`colorPalette('@{error-color}', 3) `;
@alert-error-bg-color: ~`colorPalette('@{error-color}', 1) `;
@alert-error-icon-color: @error-color;
@screen-xxl: 1600px;
@screen-xxl-min: @screen-xxl;

@list-empty-text-padding: @padding-md;
@list-item-padding: @padding-sm 0;
@list-item-content-margin: 0 0 @padding-md 0;
@list-item-meta-margin-bottom: @padding-md;
@list-item-meta-avatar-margin-right: @padding-md;
@list-item-meta-title-margin-bottom: @padding-sm;

// Grid system
@grid-columns: 24;
@grid-gutter-width: 0;

// Layout
@layout-bo html,
body {
  font-family: @font-family;
}
@supports (font-variation-settings: normal) {
  html,
  body {
    font-family: @variable-font-family;
  }
}
.drawer-wrapper {
  max-width: 100%;
}
.form-buttons button {
  margin-top: 1rem;
  margin-right: 1rem;
}
* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.centered-tabs .ant-tabs-nav-container {
  text-align: center;
}
.no-shadow .chrome-picker {
  box-shadow: none !important;
}
.anticon.flipped {
  svg {
    transform: rotate(180deg);
  }
}
.strikethrough {
  text-decoration: line-through;
}

.ant-input,
.ant-select,
.ant-select-item,
.ant-picker-input > input,
.ant-input-number,
.ant-form-item-label > label {
  font-size: 16px;
}

.ant-input-group-addon .ant-select[formControlName='country'] {
  min-width: 4.5rem;
}
